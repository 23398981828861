<template>
  <div>
    <strong>{{ timeLimit("dd-MM") }}</strong>
    <br />
    {{ $t("role.orders.tour.table.closeHour") }} {{ timeLimit("HH:mm") }}
  </div>
</template>

<script>
import get from "lodash/get";
import { formatDate } from "../../../../../../helpers/common";

export default {
  name: "OrderRoundClose",

  data() {
    return {
      data: {},
    };
  },

  methods: {
    getAccount() {
      return get(this.data, "porder");
    },

    timeLimit(format = "dd-MM-yyyy HH:mm") {
      const timeLimit = get(this.data, "time_limit", null);
      return timeLimit ? formatDate(new Date(timeLimit), format) : "--";
    },
  },
};
</script>
