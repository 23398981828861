<template>
  <div>
    {{ getProducersCount() }} {{ $t("role.orders.tour.table.producers") }}
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  name: "OrderRoundProducers",

  methods: {
    getProducersCount() {
      return get(this.data, "count_producers", 0);
    },
  },
};
</script>
